import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";

export function Welcome() {
  return (
    <Flex
      background="white"
      position="fixed"
      minHeight="100%"
      p={6}
      flexDir="column"
      maxH={"50%"}
    >
      <Image
        alignSelf="center"
        mb="12px"
        width="300px"
        src={"/logo.png"}
        alt=""
      />
      <Box as="h1" margin="auto">
        Atotal Insuretech adatkezelési tájékoztató
      </Box>
      <Box fontSize={11} margin="auto" mb={2}>
        Hatályos: 2022.05.23.
      </Box>
      <Box flexGrow={1} fontSize={14} minHeight="100%" overflow="scroll">
        <Box mb={2}>
          A jelen adatkezelési tájékoztató keretében, AZ EURÓPAI PARLAMENT ÉS A
          TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.) a természetes
          személyeknek a személyes adatok kezelése tekintetében történő
          védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK
          rendelet hatályon kívül helyezéséről (a továbbiakban: Adatvédelmi
          Rendelet) alapján tájékoztatjuk Önt, mint érintettet arról, hogy az Ön
          személyes adatait hogyan és milyen célokkal kezeljük.
        </Box>
        <Box mb={2}>
          A személyes adatok kezelője: az{" "}
          <Box as="span" fontWeight={600}>
            Atotal Insurtech Kft.
          </Box>{" "}
          A társaság az IVASS által vezetett olasz Biztosítói Csoportok
          Nyilvántartásában 26-os számon szereplő{" "}
          <Box as="span" fontWeight={600}>
            Generali Csoport
          </Box>
          hoz tartozik.
        </Box>
        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Az adatkezelő elérhetőségei:
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Név
          </Box>
          : Atotal Insurtech Kft.
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Székhely
          </Box>
          : 1158 Budapest, Késmárk Utca 14/B.
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            E-mail
          </Box>
          :{" "}
          <Link href={"mailto:info@atotalinsurtech.com"}>
            info@atotalinsurtech.com
          </Link>
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Telefon
          </Box>
          <Box as="span">: 06/1-4334-101</Box>
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Cégjegyzékszáma
          </Box>
          <Box as="span">: 01-09-071992</Box>
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Adószáma
          </Box>
          <Box as="span">: 10445608-4-42</Box>
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Nyilvántartja
          </Box>
          <Box as="span">: a Fővárosi Törvényszék Cégbírósága</Box>
        </Box>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Az adatvédelmi tisztviselő szervezet neve és elérhetőségei:
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Név
          </Box>
          : Generali Biztosító Zrt.
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            E-mail
          </Box>
          :{" "}
          <Link href={"mailto:adatkezeles@generali.com"}>
            adatkezeles@generali.com
          </Link>
        </Box>
        <Box>
          <Box as="span" fontWeight={600}>
            Levelezési cím
          </Box>
          : 7602 Pécs, Pf. 888
        </Box>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Általános fogalmak
        </Box>
        <UnorderedList mb={2}>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              érintett
            </Box>
            : bármely meghatározott, személyes adat alapján azonosított vagy –
            közvetlenül vagy közvetve – azonosítható természetes személy;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              személyes adat
            </Box>
            : azonosított vagy azonosítható természetes személyre („érintett”)
            vonatkozó bármely információ; azonosítható az a természetes személy,
            aki közvetlen vagy közvetett módon, különösen valamely azonosító,
            például név, szám, helymeghatározó adat, online azonosító vagy a
            természetes személy testi, fiziológiai, genetikai, szellemi,
            gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy
            több tényező alapján azonosítható;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              különleges adat
            </Box>
            : faji vagy etnikai származásra, politikai véleményre, vallási vagy
            világnézeti meggyőződésre vagy szakszervezeti tagságra utaló
            személyes adatok, valamint a természetes személyek egyedi
            azonosítását célzó genetikai és biometrikus adatok, az egészségügyi
            adatok és a természetes személyek szexuális életére vagy szexuális
            irányultságára vonatkozó személyes adatok;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              adatkezelő
            </Box>
            : az a természetes vagy jogi személy, közhatalmi szerv, ügynökség
            vagy bármely egyéb szerv, amely a személyes adatok kezelésének
            céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha
            az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog
            határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére
            vonatkozó különös szempontokat az uniós vagy a tagállami jog is
            meghatározhatja;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              adatkezelés
            </Box>
            : a személyes adatokon vagy adatállományokon automatizált vagy nem
            automatizált módon végzett bármely művelet vagy műveletek
            összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás,
            átalakítás vagy megváltoztatás, lekérdezés, betekintés,
            felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő
            hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás,
            korlátozás, törlés, illetve megsemmisítés;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              adatfeldolgozó
            </Box>
            : az a természetes vagy jogi személy, közhatalmi szerv, ügynökség
            vagy bármely egyéb szerv, amely az adatkezelő nevében személyes
            adatokat kezel;
          </ListItem>
          <ListItem mb={1}>
            {" "}
            <Box as="span" fontWeight={600}>
              adatvédelmi incidens
            </Box>
            : a biztonság olyan sérülése, amely a továbbított, tárolt vagy más
            módon kezelt személyes adatok véletlen vagy jogellenes
            megsemmisítését, elvesztését, megváltoztatását, jogosulatlan
            közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi;
          </ListItem>
        </UnorderedList>
        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Alapelvek
        </Box>
        <Box mb={2}>Az Atotal Insuretech felel az alábbiak betartásáért:</Box>
        <UnorderedList>
          <ListItem mb={1}>
            a személyes adatokat jogszerűen és tisztességesen, valamint az
            érintett számára átlátható módon kezeli („jogszerűség, tisztességes
            eljárás és átláthatóság”);
          </ListItem>
          <ListItem mb={1}>
            a személyes adatokat csak meghatározott, egyértelmű és jogszerű
            célból gyűjti és azokat nem kezeli ezekkel a célokkal össze nem
            egyeztethető módon („célhoz kötöttség”);
          </ListItem>
          <ListItem mb={1}>
            a kezelt személyes adatok az adatkezelés céljai szempontjából
            megfelelőek és relevánsak legyenek, és a szükségesre korlátozódjanak
            („adattakarékosság”);
          </ListItem>
          <ListItem mb={1}>
            a személyes adatok pontosak és szükség esetén naprakészek legyenek
            és minden észszerű intézkedést megtesz annak érdekében, hogy az
            adatkezelés céljai szempontjából pontatlan személyes adatokat
            haladéktalanul törölje vagy helyesbítse („pontosság”);
          </ListItem>
          <ListItem mb={1}>
            a személyes adatokat olyan formában tárolja, amely az érintettek
            azonosítását csak a személyes adatok kezelése céljainak eléréséhez
            szükséges ideig teszi lehetővé („korlátozott tárolhatóság”);
          </ListItem>
          <ListItem mb={1}>
            a személyes adatokat oly módon kezeli, hogy megfelelő technikai vagy
            szervezési intézkedések alkalmazásával biztosítva legyen a személyes
            adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes
            kezelésével, véletlen elvesztésével, megsemmisítésével vagy
            károsodásával szembeni védelmet is ideértve („integritás és bizalmas
            jelleg”).
          </ListItem>
        </UnorderedList>
        <Box mb={2}>
          Az adatok megismerésére jogosultak az{" "}
          <Box as="span" fontWeight={600}>
            Atotal Insurtech Kft.
          </Box>{" "}
          mindenkori munkavállalói, valamint a vele vállalkozói szerződésben
          álló szakértők.
        </Box>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          I. Kapcsolódó jogszabályok
        </Box>
        <UnorderedList>
          <ListItem mb={1}>
            AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016.
            április 27.) a természetes személyeknek a személyes adatok kezelése
            tekintetében történő védelméről és az ilyen adatok szabad
            áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
            helyezéséről
          </ListItem>
          <ListItem mb={1}>
            A Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlása az
            előzetes tájékoztatás adatvédelmi követelményeiről
          </ListItem>
          <ListItem mb={1}>
            Eker tv.: Az elektronikus kereskedelmi szolgáltatások valamint az
            információs társadalomról összefüggő szolgáltatások egyes
            kérdéseiről szóló 2001. évi CVIII. törvény
          </ListItem>
          <ListItem mb={1}>
            Infotv.: Az információs önrendelkezési jogról és az
            információszabadságról szóló 2011. évi CXII. törvény
          </ListItem>
          <ListItem mb={1}>
            2005. évi XC. törvény az elektronikus információszabadságról;
          </ListItem>
          <ListItem mb={1}>
            2003. évi C. törvény az elektronikus hírközlésről (kifejezetten a
            155.§-a)
          </ListItem>
          <ListItem mb={1}>
            2008. évi XLVII. törvény – a fogyasztókkal szembeni tisztességtelen
            kereskedelmi gyakorlat tilalmáról
          </ListItem>
        </UnorderedList>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          II. Adatvédelmi jogok és jogorvoslati lehetőségek, egyéb információk
        </Box>
        <Box mb={2} as="h3" textDecoration="underline" fontWeight={600}>
          Az Ön jogai
        </Box>
        <Box mb={2}>
          Az GDPR 15-20. cikkei alapján Ön jogosult arra, hogy az Atotal
          Insuretech által kezelt személyes adatai tekintetében
        </Box>
        <UnorderedList>
          <ListItem mb={1}>a személyes adatokhoz hozzáférjen;</ListItem>
          <ListItem mb={1}>a személyes adatok helyesbítését kérje;</ListItem>
          <ListItem mb={1}>a személyes adatok törlését kérje;</ListItem>
          <ListItem mb={1}>
            a személyes adatok kezelésének korlátozását kérje;
          </ListItem>
          <ListItem mb={1}>
            tiltakozzon a személyes adatai kezelése ellen;
          </ListItem>
          <ListItem mb={1}>
            a személyes adatokat megkapja és azokat egy másik adatkezelőnek
            továbbítsa, amennyiben ennek jogszabályi előfeltételei fennállnak
            (adathordozhatósághoz való jog);
          </ListItem>
          <ListItem mb={1}>
            amennyiben a személyes adatok kezelésére hozzájárulása alapján kerül
            sor, úgy hozzájárulását bármikor visszavonhatja.
          </ListItem>
        </UnorderedList>
        <Box mb={2}>
          Ön a fenti jogok gyakorlására vonatkozó kérelmét a fent megjelölt
          elérhetőségre küldheti el. Az Atotal Insuretech indokolatlan késedelem
          nélkül, de legkésőbb a kérelem beérkezésétől számított 30 napon belül
          nyújt tájékoztatást a kérelem nyomán hozott intézkedésekről.
          Amennyiben az Atotal Insuretech nem tesz intézkedéseket, késedelem
          nélkül, de legkésőbb a kérelem beérkezésétől számított 30 napon belül
          nyújt tájékoztatást az intézkedés elmaradásának okairól. Amennyiben Ön
          nem ért egyet az Atotal Insuretech válaszával vagy intézkedésével,
          akkor jogorvoslati lehetőségek állnak rendelkezésére. Az Atotal
          Insuretech minden olyan címzettet tájékoztat valamennyi
          helyesbítésről, törlésről vagy adatkezelés-korlátozásról, akivel,
          illetve amellyel a személyes adatot közölték, kivéve, ha ez
          lehetetlennek bizonyul, vagy aránytalanul nagy erőfeszítést igényel.
          Kérésre az Atotal Insuretech tájékoztatást ad a címzettekről.
        </Box>

        <Box mb={2}>II.1. Hozzáférés joga</Box>
        <Box mb={2}>
          Ön jogosult arra, hogy az Atotal Insuretech-től visszajelzést kapjon
          arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e,
          és ha ilyen adatkezelés folyamatban van, Ön jogosult arra, hogy a
          személyes adatokhoz hozzáférést kapjon, valamint tájékoztatást kapjon
          a kezelésükkel kapcsolatos körülményekről. A kért tájékoztatás a GDPR
          15. cikke alapján többek között a következő adatokra terjedhet ki: az
          adatkezelés céljai; a személyes adatok kategóriái; azon címzettek vagy
          címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat
          az Atotal Insuretech közölte vagy közölni fogja; a személyes adatok
          tárolásának tervezett időtartama; valamint amennyiben az adatokat nem
          közvetlenül Öntől gyűjtötték, a forrásukra vonatkozó minden elérhető
          információ.
        </Box>

        <Box mb={2}>II.2. Helyesbítés</Box>
        <Box mb={2}>
          Ön jogosult arra, hogy kérésére az Atotal Insuretech indokolatlan
          késedelem nélkül helyesbítse a pontatlan személyes adatokat, valamint,
          hogy kérje a hiányos személyes adatok kiegészítését.
        </Box>

        <Box mb={2}>
          II.3. A törléshez való jog („az elfeledtetéshez való jog")
        </Box>
        <Box mb={2}>
          Ön jogosult arra, hogy kérésére az Atotal Insuretech indokolatlan
          késedelem nélkül törölje a személyes adatokat, ha az alábbi indokok
          valamelyike fennáll:
        </Box>
        <UnorderedList>
          <ListItem mb={1}>a személyes adatokra már nincs szükség;</ListItem>
          <ListItem mb={1}>
            ha az adatkezelés alapját képző hozzájárulás visszavonásra kerül, és
            az adatkezelésnek nincs más jogalapja;
          </ListItem>
          <ListItem mb={1}>
            Ön tiltakozik az adatkezelés ellen és nincs elsőbbséget élvező
            jogszerű ok az adatkezelésre;
          </ListItem>
          <ListItem mb={1}>
            ha a személyes adatokat az Atotal Insuretech jogellenesen kezelte;
          </ListItem>
          <ListItem mb={1}>
            ha a személyes adatokat jogszabály alapján törölni kell.
          </ListItem>
          <ListItem mb={1}>
            Az adatot az Atotal Insuretech nem törli, amennyiben az adatkezelés
            a következő okok valamelyikéből szükséges: (i) a véleménynyilvánítás
            szabadságához és a tájékozódáshoz való jog gyakorlása céljából; (ii)
            a személyes adatok kezelését előíró jogi kötelezettség teljesítése
            céljából; (iii) vagy jogi igények előterjesztéséhez,
            érvényesítéséhez, illetve védelméhez szükséges.
          </ListItem>
        </UnorderedList>

        <Box mb={2}>II.4. Az adatkezelés korlátozásához való jog</Box>
        <Box mb={2}>
          Ön jogosult arra, hogy kérésére az Atotal Insuretech korlátozza az
          adatkezelést, amennyiben az alábbiak valamelyike teljesül:
        </Box>
        <UnorderedList>
          <ListItem mb={1}>
            Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás
            arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az Atotal
            Insuretech ellenőrizze a személyes adatok pontosságát
          </ListItem>
          <ListItem mb={1}>
            az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, és
            ehelyett kéri azok felhasználásának korlátozását;
          </ListItem>
          <ListItem mb={1}>
            az Atotal Insuretech-nek már nincs szüksége a személyes adatokra
            adatkezelés céljából, de Ön igényli azokat jogi igények
            előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy Ön
            tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az
            időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
            adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos
            indokaival szemben.
          </ListItem>
        </UnorderedList>
        <Box mb={2}>
          Az adatkezelés korlátozása esetén a korlátozással érintett személyes
          adatokat a tárolás kivételével csak az Ön hozzájárulásával, vagy jogi
          igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más
          természetes vagy jogi személy jogainak védelme érdekében, vagy az
          Európai Unió, illetve valamely tagállam fontos közérdekéből lehet
          kezelni. A korlátozás feloldásáról az Atotal Insuretech előzetesen
          tájékoztatást nyújt Önnek.
        </Box>

        <Box mb={2}>II.5. A tiltakozáshoz való jog</Box>
        <Box mb={2}>
          Ön jogosult arra, hogy a saját helyzetével kapcsolatos okokból
          bármikor tiltakozzon személyes adatainak az Atotal Insuretech jogos
          érdekén alapuló kezelése ellen. Ebben az esetben az Atotal Insuretech
          a személyes adatokat nem kezelheti tovább, kivéve, ha az adatkezelő
          bizonyítja, hogy az adatkezelést olyan kényszerítő erejű jogos okok
          indokolják, amelyek elsőbbséget élveznek az Ön érdekeivel, jogaival és
          szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez,
          érvényesítéséhez vagy védelméhez kapcsolódnak.
        </Box>

        <Box mb={2}>II.6. Az adathordozhatósághoz való jog</Box>
        <Box mb={2}>
          Amennyiben az mások jogait és szabadságait nem sérti, Ön jogosult
          arra, hogy személyes adatait tagolt, széles körben használt, géppel
          olvasható formátumban megkapja. Jogosult továbbá arra, hogy ezeket az
          adatokat az Atotal Insuretech közvetlenül továbbítsa egy másik
          adatkezelő részére, ha
        </Box>
        <UnorderedList>
          <ListItem mb={1}>
            az adatkezelés az Ön hozzájárulásán alapul vagy olyan szerződés
            teljesítéséhez szükséges, amelyben Ön az egyik fél, vagy az a
            szerződés megkötését megelőzően az Ön kérésére történő lépések
            megtételéhez szükséges; és
          </ListItem>
          <ListItem mb={1}>
            az adatkezelés automatizált módon történik, azaz a személyes adatok
            kezelésére informatikai rendszerben és nem papír alapon kerül sor.
            <Link href="#appendix">
              <Box as="sup" fontWeight={600}>
                [1]
              </Box>
            </Link>
          </ListItem>
        </UnorderedList>

        <Box mb={2} textDecoration="underline" fontWeight={600}>
          Jogorvoslat
        </Box>
        <Box mb={2}>
          Amennyiben Ön nem ért egyet az Atotal Insuretech válaszával vagy
          intézkedésével, akkor panasszal élhet a Nemzeti Adatvédelmi és
          Információszabadság Hatóságnál (cím: 1125 Budapest, Szilágyi Erzsébet
          fasor 22/c; levelezési cím: 1530 Budapest, Postafiók: 5.; telefon:{" "}
          <Link href="tel:+3613911400">+36-1-391-1400</Link>
          <Box as="span">; fax: +36-1-391-1410; e-mail: </Box>
          <Link href="mailto:ugyfelszolgalat@naih.hu">
            ugyfelszolgalat@naih.hu
          </Link>
          ). Ön személyiségi jogai megsértése esetén pert indíthat a Fővárosi
          Törvényszéken, vagy az Ön lakóhelye szerint illetékes törvényszéken
          (további információ:{" "}
          <Link target="_blank" href="https://birosag.hu/torvenyszekek">
            https://birosag.hu/torvenyszekek
          </Link>
          ).
        </Box>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Az igénybe vett adatfeldolgozók
        </Box>
        <Box mb={2}>
          Az Atotal Insuretech részéről adatfeldolgozóként az Atotal Insuretech
          szolgáltatásaival kapcsolatban az alábbi adatfeldolgozók járhatnak el,
          akik az adatokat megismerhetik és az Atotal Insuretech utasításai
          alapján feldolgozhatják.
        </Box>
        <Box mb={2}>
          Ezen adatfeldolgozók címzetti körök szerint az alábbiak:
        </Box>
        <OrderedList type="a">
          <ListItem mb={1}>elektronikus adatfeldolgozó szolgáltatók,</ListItem>
          <ListItem mb={1}>
            informatikai (rendszerkarbantartó, üzemeltető) szolgáltatók,
          </ListItem>
          <ListItem mb={1}>
            információbiztonsági és szoftverfejlesztési szolgáltatók,
          </ListItem>
          <ListItem mb={1}>kárrendező és kárszakértő szolgáltatók,</ListItem>
          <ListItem mb={1}>
            bíróságok, hatóságok, hatáskörtől függően felügyeleti hatóságok.
          </ListItem>
        </OrderedList>

        <Box as="h2" mb={3} mt={3} textDecoration="underline" fontWeight={600}>
          Lehetséges változások a Tájékoztatóban
        </Box>
        <Box mb={2}>
          Jelen Tájékoztatót az Atotal Insuretech bármikor jogosult
          megváltoztatni. Ebben az esetben az Atotal Insuretech a frissített
          változatot közzéteszi. Az érdemi változásokról az Atotal Insuretech
          mindig megfelelő tájékoztatást nyújt. Amennyiben kérdés merül fel arra
          vonatkozóan, hogy melyik tájékoztatás alkalmazandó, keresse fel az
          Atotal Insuretech-t a fent megjelölt elérhetőségen.
        </Box>

        <Box id="appendix" mb={2} fontSize={10}>
          <Box as="sup" fontWeight={600}>
            1
          </Box>
          Az előző két feltételnek együttesen kell teljesülnie, azaz csak akkor
          lehet élni az adathordozhatósági joggal, ha Ön hozzájárult adatai
          kezeléséhez (azaz hozzájárulás a jogi alapja az adatkezelésnek) vagy
          szerződésen alapul az adatkezelés és az adatkezelés IT rendszerekben
          úgy történik, hogy emberi beavatkozásra nem kerül sor.
        </Box>
      </Box>

      <HStack w="full">
        <Button background="red.600" opacity="0.6" w="full">
          Elutasítom
        </Button>
        <Box display="flex" w="full" flexDir="column" gap={2}>
          <Link w="full" href={`/precall`} textDecor="none!important">
            <Button background="blue.500" w="full">
              Elfogadom
            </Button>
          </Link>
          {/* <Link w="full" href={`/precall2`} textDecor="none!important">
            <Button background="blue.500" w="full">
              Elfogadom
            </Button>
          </Link> */}
        </Box>
      </HStack>
    </Flex>
  );
}
